export default {
  home: {
    RebateAccountNumber: 'リベート口座番号',
    totalCommission: '合計手数料',
    APPLY_FOR_A_REBATE: 'リベートの申請',
    availableBalance: '利用可能残高',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '振替',
    TOTAL_VOLUME: '合計取引量',
    NET_FUNDING: '純資金',
    DEPOSITS: '入金',
    WITHDRAW: '出金',
    OPEND_ACCOUNT: '開設口座',
    PERFORMANCE: 'パフォーマンス',
    MOST_TRADED_INSTRUMENTS: '最も取引されている商品',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '今月の最もパフォーマンスの良い口座',
    RECENTLY_OPENED_ACCOUNTS: '最近開設された口座',
    ACCOUNT_MANAGER: '口座マネージャー',
    Account: '口座',
    Name: '名前',
    Volume: '取引量',
    Rebate: 'リベート',
    Balance: '残高',
    ContactClient: 'お客様への連絡',
    applyRebateSuccessTip:
      'おめでとうございます。リベートリクエストを受け取りました。 | {amount} はまもなくリベートアカウント {account} に振り込まれます。 | 処理が完了すると、資金を出金か振り替えることができます。',
    HOVER: '前日のリベートは毎日 09:00 - 11:00 AEST の間に計算され、処理されます。',
    blacklist: '現在、このアカウントではリベートを行うことができません。アカウントマネージャーにお問い合わせください。',
  },
  liveChat: { header: 'ライブチャット' },
  contactUs: {
    tip: 'ご質問がある場合や詳しい情報をご希望の場合は、以下の連絡先のいずれかからご連絡ください。<br> すべてのお問い合わせに 24 時間以内に回答できるよう努めます。',
  },
  fileUploader: {
    supportedFormat: 'サポートされているファイルの種類: png、jpg、jpeg、bmp、pdf、doc、docx',
    supportedFormatCustom: 'サポートされているファイルの種類: {files}',
    maxSize: 'アップロード可能なファイルの最大サイズ: {maxSize}MB',
    maxFileNum: 'ファイル数の上限: {maxFileNum}',
    failedFileExtension: '正しいファイルの種類をアップロードしてください',
  },
  notification: {
    pendingIdentityProof: {
      header: 'アカウント申請の処理中',
      id: 'ID は',
      poa: '住所は',
      both: 'ID と住所証明は',
      content: '{doc} の証明は現在処理中で、まもなく完了します。',
      single: '',
      both2: '',
    },
  },
  identityProof: {
    home: {
      header: '',
      content: '',
      header1: '書類をアップロードしてください',
      content1: '書類をアップロードして申請を完了してください。',
    },
    popup: {
      header:
        'こんにちは、{name} さん。リアル口座を確定させる前に、確認のために次のドキュメントをアップロードする必要があります。',
      addressProofSpan:
        '「<strong>\n <b>住所の確認</b>\n </strong>\n <br />\n <I>\n 以下の書類のうち <b>1 つ</b> を提出してください\n <br /><br />\n </I>\n - 最近の公共料金の請求書のコピー </br> (3 か月以内のもの)\n <br />\n <b class=""or"">または</b><br />\n - 最近の銀行取引明細書のコピー </br> (3 か月以内のもの)\n <br />\n <br />\n <b>重要!</b> 書類に記載される名前には、あなたのフルネームと現在の住所が明確に記載されていなければなりません。\n <br />"',
    },
    reasonType: {
      4: '書類が不完全',
      5: '書類が未承認',
      6: 'カラーコピーが必要',
      8: '口座開設名が一致しない',
      9: '住所証明の発行日が不適格',
    },
    reason: '書類を確認できませんでした。<br> 理由: {reason}。もう一度お試しください。',
    success: 'ありがとうございます。書類を正常に受け取り、現在確認処理中です。',
    shufti: { content: '', countryOfDocument: '' },
  },
  ibAccounts: {
    ibAccount: 'IB アカウント',
    subIbs: 'サブ IB アカウント',
    tradingList: '取引リスト',
    OpeningList: '開始リスト',
    closeList: '終了リスト',
    tradingListColumn: {
      ticket: 'チケット ID',
      openTime: '開始時間',
      type: 'タイプ',
      volume: 'ボリューム',
      item: 'アイテム',
      openPrice: '開始価格',
      closeTime: '終了時間',
      closePrice: '終了価格',
      commission: '手数料',
      profit: '利益',
    },
  },
  withdraw: {
    warning: {
      desc: '以下の点にご注意ください:',
      desc1: 'エンドユーザーには銀行振込手数料がかかります。通常、135～250 円です。',
      desc2: '午後 2 時以降に行われた出金リクエストは、遅延または翌日に処理される場合があります。',
    },
    default: {
      selectCC: 'クレジットカードを選択',
      selectBA: '銀行口座を選択',
      anotherCC: 'クレジットカードを手動で入力',
      anotherBA: '銀行口座を追加',
      remember: 'アカウントを記憶',
      withdrawlFeeAlert: 'Unionpay 経由で出金する場合、出金額が 100ドル未満の場合、20ドル の手数料がかかります。',
    },
    transfer: {
      info: '{platform} は、1 か月あたり <u>1 回</u> の出金に対して請求される銀行手数料を払い戻します。その月内の追加の出金には、出金通貨の 20 単位の銀行手数料が発生します。',
      label: {
        upload: '銀行取引明細書のコピー（最新～過去3か月分）をアップロードしてください。',
      },
      formValidation: {
        upload: '銀行取引明細書をアップロードしてください',
        digits11: '11 桁を入力してください',
        digitsAny: '{number} 桁を入力してください',
        digitsAnytoAny: '{number} 桁から {number2} 桁まで入力してください',
        alphanumericAnytoAny: '{number} 桁から {number2} 桁までの英数字を入力してください',
      },
      note: '出金リクエストを処理するには、パスポートを提供してください。企業アカウントの場合は、請求書を {email} に提供してください',
    },
    REBATE_WITHDRAW_REQUEST: 'リベート出金リクエスト',
    PleaseSelectDesiredWithdrawMethod: 'ご希望の出金方法を選択してください',
    BankName: '銀行名',
    bankCode: '銀行コード',
    bankBranchCode: '支店コード',
    bankAccountCurrency: '銀行口座通貨',
    BankAccountsName: '銀行口座名',
    FasaPayAccountName: 'FasaPay 口座名',
    AustralianBankName: 'オーストラリアの銀行名',
    BankAccountNumber: '銀行口座番号',
    FasaPayAccountNumber: 'FasaPay 口座番号',
    BankAccountBeneficiary: '銀行口座受取人',
    BSB: 'BSB',
    SortCode: 'ソートコード',
    skrillEmail: 'Skrill メール',
    NetellerEmail: 'Neteller メール',
    PerfectMoneyEmail: 'Perfect Money メール',
    BitwalletEmail: 'Bitwallet メール',
    SticpayEmail: 'SticPay メール',
    BitcoinEmail: 'Bitcoin ウォレット アドレス',
    USDTEmail: 'Tether ウォレット アドレス',
    USDCEmail: 'USDC (ERC-20) ウォレット アドレス',
    EthEmail: 'ETH ウォレット アドレス',
    BankAccountName: '銀行口座名',
    BankBranch: '銀行支店 (3桁の支店番号)',
    BankAccountType: '銀行口座の種類',
    Province: '県',
    City: '市',
    BankAddress: '銀行住所',
    AccountNumberIBAN: '口座番号',
    BankBeneficiaryName: '銀行受取人名',
    BankAccountBeneficiaryName: '銀行口座受取人名',
    AccountHoldersAddress: '口座名義人の住所',
    SelectWithdrawCard: '出金カードを選択',
    Swift: 'Swift',
    SwiftAud: 'Swift(AUD 以外の口座)',
    ABA_SortCodeABA: 'ABA/ソートコード',
    DocumentType: '書類タイプ',
    DocumentDetails: '書類詳細',
    PixKeyType: 'PIX キータイプ',
    PixKey: 'PIX キー',
    AccountType: '取引口座タイプ',
    AccountDetails: '取引口座詳細',
    ImportantNotes: '重要事項',
    ifscCode: 'IFSC コード',
    uploadDocument: '銀行取引明細書のコピーをアップロードしてください（過去 3 か月分）',
    YourPaymentGreaterThanAvailableBalance: 'お支払い金額が利用可能残高を超えています',
    integer: '整数入力のみサポートされています',
    CannotUseMoreThanTwoDecimalValues: '小数点以下 2 桁を超える数字および負の数字は使用できません',
    YourPaymentIsBeingProcessed: '出金申請が送信されました',
    Itcannotbe0: '0 にすることはできません',
    noCardWarn: '出金に使用できるカードがありません。まず出金詳細でカードをリンクしてください',
    amtLarger:
      '最小出金額は {currencyType}{minLimit} です。{currencyType}{minLimit} 未満の残高は全額出金する必要があります',
    blackList: '残念ながら、現在出金リクエストを送信できません。詳細については、{supportEmail} にお問い合わせください',
    cryptoAlert:
      'BUSDT 経由で入金または出金することはできませんので、アドレスと暗号通貨が当社が受け入れるチェーンと通貨と一致していることを確認してください。一致していない場合は資金を失う可能性があります。',
    NetellerChargeAlert:
      'Neteller の出金は 1 回の取引につき 500,000 ドルに制限されており、2% の手数料 (上限 30 ドル) が適用されます。',
    FasaPayChargeAlert: 'FasaPay は出金ごとに 0.5% の取引手数料を請求します。',
    SkrillChargeAlert: 'Skrill では、出金ごとに 1% の取引手数料がかかります。',
    name: '名前',
    phone: '電話番号',
    address: '住所',
    email: 'メールアドレス',
    status: {
      withdrawalSubmitted: '送信済み',
      withdrawalCanceled: 'キャンセル済み',
      withdrawalAuditing: '処理中',
      withdrawalRejected: '拒否',
      withdrawalAccepted: '処理中',
      withdrawalPayFailed: '失敗',
      withdrawalSuccess: '処理済み',
      withdrawalFailed: '失敗',
      withdrawalPending: '保留中',
      withdrawalPartialFailed: '失敗',
      transferSubmitted: '処理中',
      transferSuccess: '処理済み',
      transferRejected: '拒否',
      transferFailed: '失敗',
      transferProcessing: '処理中',
    },
    formErrorMsg: {
      bankName: '銀行名は必須です',
      bsb: 'BSB は必須です',
      sortCode: 'ソートコードは必須です',
      beneName: '銀行受取人名は必須です',
      bankCodeReq: '銀行コードは必須です',
      bankBranchCodeReq: '支店コードは必須です',
      accNum: '銀行口座番号は必須です',
      swift: 'Swift は必須です',
      bankAddress: '銀行住所は必須です',
      holderAddress: '名義人住所は必須です',
      amt: '金額は必須です',
      firstSixDigits: '最初の 6 桁を入力してください',
      lastFourDigits: '最後の 4 桁を入力してください',
      branchName: '支店名は必須です',
      email: 'メール アドレスは必須です',
      accName: '口座名は必須です',
      province: '銀行の所在地は必須です',
      city: '銀行の市区町村は必須です',
      bankCard: 'カードを選択してください',
      emailFormat: '正しいメールアドレスを入力してください',
      amt0: '金額は 0 にできません',
      ifscCode: 'IFSC コードは必須です',
      bitcoin: 'BTC ウォレット アドレスは必須です',
      bitcoinAddressLength: 'BTC ウォレット アドレスは 26 文字から 42 文字の間でなければなりません',
      usdt: 'USDT ウォレット アドレスは必須です',
      eth: 'ETH ウォレット アドレスは必須です',
      usdc: 'USDC (ERC-20) ウォレット アドレスは必須です',
      dynamicReq: '{fieldname} は必須です',
      uploadDocument: '銀行取引明細書をアップロードしてください',
      bankAccType: '取引口座タイプは必須です',
      branchCode: '支店コードは必須です',
      accReq: '口座番号は必須です',
      bitcoinFormat: 'BTC ウォレット アドレスの形式が正しくありません',
      chain: 'チェーンは必須です',
      walletFormatIncorrect: '{type} ウォレット アドレスの形式が正しくありません',
      invalidCode: 'コードが必要です',
      onlyKatakanaAndSymbols:
        '無効な入力です。カタカナ（例：カタカナ）と記号（例：(), -, ., /）のみを使用してください。',
    },
    note: "「最近の変更により、当行は一部の国に登録されているお客様の国際銀行送金を処理できない可能性があります。<br/>国際銀行送金で出金する必要がある場合は、+44 2080 363 883 または <a href='mailto:{mail}'>{mail}</a> までカスタマー サポートにお問い合わせください。<br/>\n ご不便をおかけして申し訳ございません。」",
    minAmountWarn:
      '適用された出金額は、適用された決済ゲートウェイの最小額を下回っています。出金リクエストを送信する前に、取引口座残高を最小額以上に入金してください。詳細については、<a href="https://{regulatorUrl}/clients/accounts/withdraw/">こちら</a>をご覧ください。',
    localdepositor: {
      depositor: 'ローカル デポジター',
      tip: '出金手数料については、お近くの預金者にお問い合わせください。',
    },
    emailFormat: '正しいメールアドレスを入力してください',
    neteller: {
      field: { email: 'Neteller メール' },
      formValidation: { emailReq: 'Neteller メールは必須です' },
    },
    ebuy: {
      field: { email: 'Ebuy メール' },
      formValidation: { emailReq: 'Ebuy メールは必須です' },
    },
    bitcoin: {
      field: { address: 'Bitcoin ウォレット アドレス' },
      formValidation: {
        addressReq: 'Bitcoin ウォレット アドレスは必須です',
        addressFormat: 'Bitcoin ウォレット アドレスの形式が正しくありません',
        addressLength: 'Bitcoin ウォレット アドレスは 26 文字から 42 文字まででなければなりません',
      },
    },
    eth: {
      field: { address: 'ETH ウォレット アドレス' },
      formValidation: { addressReq: 'ETH ウォレット アドレスが必要です' },
    },
    usdc: {
      field: { address: 'USDC (ERC-20) ウォレット アドレス' },
      formValidation: {
        addressReq: 'USDC (ERC-20) ウォレット アドレスが必要です',
      },
    },
    usdt: {
      field: { address: 'USDT ウォレット アドレス', chain: 'チェーン' },
      formValidation: {
        addressReq: 'USDT ウォレット アドレスが必要です',
        chain: 'チェーンが必要です',
        walletFormatIncorrect: '{type} ウォレット アドレスの形式が正しくありません',
      },
      note: 'ERC20 の現在のガス料金は通常よりも大幅に高く、その結果、取引の処理に長い遅延が生じる可能性があります。 代わりに、対応するウォレット アドレスを提供することで TRC20 経由で資金を出金することができます。または、ガス料金を支払って ERC20 の出金を高速化することもできます。',
    },
    verificationCodeFailed: '確認コードは 3 分以内に有効になります。しばらくしてからもう一度お試しください。',
    sendVerCode: 'コードを送信',
    sendVerEmail:
      '確認コードは {sendEmail} に送信されました。さらにサポートが必要な場合は、{mail} までご連絡ください。',
    enterVerCode: '確認コードを入力',
    AccNumber: '口座番号',
    AccountNumberdigit: '口座番号の数字',
    USDTChain: 'チェーン',
    Fullname: '氏名',
    DocumentNumber: '書類番号',
    vpa: 'VPA',
    plnWithdraw: '金額は zł {money} PLN 未満である必要があります',
    usdtNote:
      'ERC20 の現在のガス料金は通常よりも大幅に高く、その結果、取引の処理に長い遅延が生じる可能性があります。 代わりに、対応するウォレット アドレスを提供することで TRC20 経由で資金を出金することができます。または、ガス料金を支払って ERC20 の出金を高速化することもできます。',
    minAmountWarnNoLink:
      '適用された出金額は、適用された支払いゲートウェイの最小額を下回っています。出金リクエストを送信する前に、アカウント残高を最小額以上に補充してください。',
    negativeBalance:
      '他のアカウント（{accounts}）の残高が現在マイナスであるため、出金リクエストをお受けできません。マイナス残高を補充するか、アカウント マネージャーに連絡してアカウント残高を帳消しにしてください。',
  },
  rebate: {
    status: {
      processed: '処理済み',
      processing: '処理中',
      rejected: '拒否',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'リベート振替リクエスト',
    transferAmountIsGreaterThanAvailableBalance: '振替金額が利用可能残高を超えています',
    yourRebateTransferHasBeenProcessed: 'リベート振替が送信されました',
    PleaseEnterTransferInformation: '正確な振替情報を入力してください',
    tradingAccount: '取引口座',
    rebateAccount: 'リベート口座',
    account: '口座',
    blackList: 'アカウント間の送金が制限されています。{supportEmail} にお問い合わせください',
  },
  transferHistory: {
    payStatus: {
      processing: '処理中',
      paid: '支払済み',
      unpaid: '未払い',
      rejected: '拒否',
    },
  },
  common: {
    selectIb: 'IBを選択',
    liveChat: {
      desc: '<li><p>ヘルプが必要ですか?</p><span>ライブ チャット</span></li>',
    },
    lang: {
      english: '英語',
      chinese: '中国語',
      french: 'フランス語',
      thai: 'タイ語',
      german: 'ドイツ語',
      spanish: 'スペイン語',
      malay: 'マレー語',
      viet: 'ベトナム語',
      indo: 'インドネシア語',
      arabic: 'アラビア語',
      japanese: '日本語',
    },
    button: {
      confirm: '確認',
      cancel: 'キャンセル',
      identityProof: '申請完了',
      upload: 'アップロード',
      selectFile: 'ファイルを選択',
      fundnow: '今すぐ入金',
      iunderstand: '了解しました',
    },
    withdrawChannel: {
      hongkongbanktransfer: '香港国内銀行振込',
      banktransfer: '銀行振込',
      aus: '銀行振込 - オーストラリア',
      int: '銀行振込 - 国際',
      chn: '銀行振込 - 中国',
      uk: '銀行振込 - 英国',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'タイの即時銀行電信送金',
      malaysiainstantbankwiretransfer: 'マレーシアの即時銀行電信送金',
      banktransferbpaypolipay: '銀行振込/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: '暗号通貨-BTC',
      cryptocurrencyusdt: '暗号通貨-USDT',
      nigeriainstantbankwiretransfer: 'ナイジェリアの即時銀行電信送金',
      vietnaminstantbankwiretransfer: 'ベトナムの即時銀行電信送金',
      indonesiainstantbankwiretransfer: 'インドネシアの即時銀行電信送金',
      philippinesinstantbankwiretransfer: 'フィリピンの即時銀行電信送金',
      southafricainstantbankwiretransfer: '南アフリカの即時銀行送金',
      indiainstantbanktransfer: 'インドの即時銀行送金',
      laosinstantbankwiretransfer: 'ラオスの即時銀行送金',
      ugandabanktransfer: 'ウガンダの現地銀行送金',
      rwandabanktransfer: 'ルワンダの現地銀行送金',
      zambiabanktransfer: 'ザンビアの現地銀行送金',
      congobanktransfer: 'コンゴの現地銀行送金',
      cameroonbanktransfer: 'カメルーンの現地銀行送金',
      burundibanktransfer: 'ブルンジの現地銀行送金',
      kenyabanktransfer: 'ケニアの現地銀行送金',
      ghanabanktransfer: 'ガーナの現地銀行送金',
      tanzaniabanktransfer: 'タンザニアの現地銀行送金',
      brazilbanktransfer: 'ブラジルの即時銀行送金',
      mexicobanktransfer: 'メキシコ即時銀行振込',
      internetbankingmexico: 'インターネット バンキング (メキシコ)',
      internetbankingbrazil: 'インターネット バンキング (ブラジル)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: '口座への振替',
      internetbankingvietnam: 'インターネット バンキング (ベトナム)',
      internetbankingsouthafrica: 'インターネット バンキング (南アフリカ)',
      internetbankingIndonesia: 'インターネット バンキング (インドネシア)',
      internetbankingPhilippine: 'インターネット バンキング (フィリピン)',
      internetbankingLaos: 'インターネット バンキング (ラオス)',
      internetbankingThailand: 'インターネット バンキング (タイ)',
      internetbankingMalaysia: 'インターネット バンキング (マレーシア)',
      localdepositor: '現地預金者',
      perubanktransfer: 'ペルー 即時銀行振込',
      colombiabanktransfer: 'コロンビア 即時銀行振込',
      ecuadorbanktransfer: 'エクアドル 即時銀行振込',
      chilebanktransfer: 'チリ即時銀行振込',
      ebuy: 'Ebuy',
      pluswallets: 'Plus ウォレット',
      beeteller: 'Beeteller',
      withdrawalServiceCharge_ibt:
        '銀行送金による出金の場合、INFINOX は 15ドルの手数料を請求します。また、利用した銀行から海外送金手数料が請求される場合があります。',
      withdrawalServiceCharge_chanel:
        '50 ドルを超える出金には手数料はかかりませんが、50ドル未満の出金には 15ドルの手数料がかかります。',
      withdrawalBankMsg: '入金時に選択した銀行に一貫性を持たせてください',
      internetbankingperu: 'インターネット バンキング (ペルー)',
      internetbankingcolumbia: 'インターネット バンキング (コロンビア)',
      internetbankingcolombia: 'インターネット バンキング (コロンビア)',
      internetbankingchile: 'インターネット バンキング (チリ)',
      internetbankingecuador: 'インターネット バンキング (エクアドル)',
      internetbankingcambodia: 'インターネット バンキング (カンボジア)',
      internetbankingmyanmar: 'インターネット バンキング (ミャンマー)',
      internetbankingkorea: 'インターネット バンキング (韓国)',
      dragonphoenix: 'インターネット バンキング (韓国)',
      cryptocurrencyethcps: '暗号通貨-ETH',
      cryptocurrencyeth: '暗号通貨-ETH',
      cryptocurrencyusdc: '暗号通貨-USDC',
      cryptocurrencyusdccps: '暗号通貨-USDC',
      cryptocurrencybtc: '暗号通貨-BTC',
      cryptocurrencyusdterc20: '暗号通貨-USDT(ERC-20)',
      cryptocurrencyusdttrc20: '暗号通貨-USDT(TRC-20)',
      malaysiabanktransfer: 'マレーシア即時銀行振込',
      indonesiabanktransfer: 'インドネシア即時銀行振込',
      vietnambanktransfer: 'ベトナム即時銀行振込',
      philippinesbanktransfer: 'フィリピン即時銀行振込',
      thailandbanktransfer: 'タイ即時銀行振込',
      cambodiabanktransfer: 'カンボジア即時銀行振込',
      laosbanktransfer: 'ラオス即時銀行振込',
      myanmarbanktransfer: 'ミャンマー即時銀行振込',
      southafricabanktransfer: '南アフリカ即時銀行振込',
      southKoreabanktransfer: '韓国即時銀行振込',
      dasshpeupi: 'UPI',
      perfectmoney: 'パーフェクトマネー',
      ghanamobilemoney: 'ガーナモバイルマネー',
    },
    country: {
      international: '国際',
      australia: 'オーストラリア',
      uk: 'イギリス',
    },
    field: {
      country_region: '国/地域',
      yes: 'はい',
      no: 'いいえ',
      ya: '',
      tidak: '',
    },
    keys: {
      SUBMIT: '送信',
      FROM: '開始',
      UPDATE: '更新',
      STATUS: 'ステータス',
      TO: '終了',
      DATE: '日付',
      pleaseSelect: '選んでください',
      amount: '金額',
      actualAmount: '実際の出金額 =',
      rebateAccount: 'リベート口座',
      comingSoon: '近日公開予定',
      tradingHistory: '取引履歴',
      noData: 'データなし',
      NAME: '名前',
      EMAIL: 'メール',
      ACCTYPE: '口座タイプ',
      PLATFORM: 'プラットフォーム',
      BASECURRENCY: '基本通貨',
      DATERANGE: '日付範囲',
      DEST: '送金先',
      ACCNUM: '口座番号',
      BALANCE: '残高',
      NONE: 'なし',
      ALL: 'すべて',
      PROFITLOST: '利益損失',
      MARGINLEVEL: 'マージン レベル (%)',
      ACCOUNTEQUITY: '口座残高',
      CREDIT: 'クレジット',
      DOWNLOAD: 'ダウンロード',
      amountOut: '出金額',
      amountIn: '入金額',
      MOBILE: 'モバイル',
      VIEW: '表示',
      ACCEQUITY: '口座有効証拠金',
    },
    dateRange: {
      today: '今日',
      yesterday: '昨日',
      lastSevenDays: '過去 7 日間',
      lastThirtyDays: '過去 30 日間',
      weekToDate: '今週の累計',
      monthToDate: '今月の累計',
      quarterToDate: '四半期の累計',
      previousWeek: '前週',
      previousMonth: '前月',
      previousQuarter: '前四半期',
    },
    products: {
      forex: '外国為替',
      commodities: 'コモディティ',
      indices: 'インデックス',
      crypto: '暗号通貨',
      bond: '債券',
      bonds: '債券',
      sharecfd: '株式 CFD',
      bond: '債券',
      gold: '金',
      minigold: 'ゴールドミニ',
      goldfutures: '金先物',
      bitcoin: 'ビットコイン',
      silver: '銀',
      silverfutures: '銀先物',
      oil: '原油',
      oilfutures: '原油先物',
      index: 'INDEX',
      indexfutures: 'INDEX先物',
      etf: 'ETF',
      forexfutures: '外国為替',
      stock: '外国為替先物',
    },
  },
  withdrawalFreeze: {
    header: '出金は一時的にサポートされていません',
    desc: 'ログインパスワードが変更されたため、アカウントのセキュリティを確保するため、T+{day} 日間は出金が一時的にサポートされていません。この制限は {hour} 時間以内に解除される予定です。',
    desc2:
      'ログインメールアドレス/電話番号が変更されたため、T+{day} 日間は一時的に出金ができません。この制限は {hour} 時間以内に解除される予定です。',
  },
  report: {
    rebate: {
      column: {
        name: '名前',
        accountNumber: '口座番号',
        accountType: '口座タイプ',
        volume: '数量',
        rebate: 'リベート',
        totalRebate: 'リベート合計',
        date: '日付',
        instrument: '投資商品',
        notionalvalue: '名目価値',
        crypto: '暗号通貨',
        sharecfd: '株式 CFD',
      },
      dropdown: {
        allInstruments: 'すべての投資商品',
        all: 'すべての',
        instruments: '投資商品',
      },
      others: {
        rebate: 'リベート',
        instrumentsTraded: '取引商品',
        lots: 'Lot',
        totalRebate: '合計リベート',
        rebates: 'リベート',
        searchBy: '名前/アカウントで検索',
      },
    },
    btn: { showAll: 'すべて表示', hide: '隠す' },
    nodataMessage: 'フィルタリングされたデータはありません。ダウンロードする前に検索条件をフィルタリングしてください。',
    timeLimitMessage: 'ダウンロードの最大クエリ範囲は 90 日間です。新しい日付範囲を更新してください。',
    trade: {
      column: {
        account: 'アカウント',
        ticket: 'チケット',
        platform: 'プラットフォーム',
        symbol: 'シンボル',
        type: 'タイプ',
        closeTime: '終了時間',
        profit: '利益',
        commission: '手数料',
      },
      download: 'ダウンロード中',
      clear: 'クリア',
      dateRangeLimit: '最大日付範囲は 3 か月です',
    },
    comingSoon: '',
    noData: '',
  },
  ibReport: {
    openedAccs: '開設済み口座',
    funding: '資金',
    account: '口座',
    Account: '',
  },
  lead: { demoAccounts: 'デモ口座' },
  pendingAccounts: {
    errorDoc:
      '以下の申請者はオンライン申請フォームに記入しましたが、アカウントを確定するために必要な身分証明書 (ID) をまだ提出していません。',
    acceptedDocType: '当社規定の身分証明書の形式は次のとおりです:',
    primaryDoc: 'プライマリID',
    passport: '有効な国際パスポートのコピー（署名ページ）',
    id: '政府発行の国民身分証明書のコピー',
    dl: '有効な運転免許証のコピー',
    address: 'セカンダリ ID',
    addressList: {
      0: '最近の公共料金の請求書のコピー。例: ガスや電気など、氏名と住所が記載されているもの (3 か月以内のもの)',
      1: 'クレジットカードの明細書または銀行の明細書のコピー (オンラインで印刷したものは不可)',
    },
    tip: '申請者には、認められている主な身分証明書と二次身分証明書のいずれか1つのコピーを提出するよう勧めてください。',
    divc: 'ID 有権者カードのコピー',
    dric: '住民 ID カードのコピー',
  },
  rebatePaymentHistory: {
    rebateHistory: 'リベート',
    withdrawHistory: '出金',
    transferHistory: '振替',
    cancelWithdraw: '出金リクエストをキャンセルしますか?',
    cancelError:
      '現在、出金手続き中です。そのため、キャンセルのリクエストはお受けできません。詳細については、ライブ チャット機能を使用してお問い合わせください。',
  },
  referralLinks: {
    copyLink: 'リンクをコピー',
    downloadPage: 'アプリをダウンロード',
    campLang: 'キャンペーン言語',
    cpy: 'コピー',
    alreadyCPY: 'コピーしました!',
    liveAcc: 'リアル口座',
    demoAcc: 'デモ口座',
    homePage: 'ホームページ',
    promotion: 'イベント プロモーション リンク',
    hintTitle: 'イベント プロモーション リンクとは何ですか?',
    hintMessage:
      'イベントプロモーションリンクはIBに提供される新しいプロモーション方法です。共有リンクはユーザーをボーナスイベントページに誘導し、登録を完了させます。リンクを共有することで、ユーザー登録の成功率を効果的に向上させることができます。',
    hintShortLink: '短縮リンクを使用して共有',
    hintShortLinkTitle: '短縮リンクとは何ですか?',
    hintShortLinkMessage:
      '短縮リンクはリンク生成ルールであり、共有リンクが他の人によって変更されるのを防ぎ、IB 開発権を保護することができます',
    customButton: 'カスタマイズ',
    copyButton: 'コピー',
    customInviteCodeHeader: 'カスタム招待コード',
    currentCode: '現在の招待コード',
    newCode: '新しい招待コード',
    notice: '注意: 招待コードには少なくとも 6 つのパラメータがあります。文字、数字、特殊でない記号を入力できます',
    inviteCodeFormatError: '招待コードの形式が間違っています。もう一度編集してください',
    inviteCodeAlreadyExist: '招待コードは他の人によって先に登録されています。もう一度入力してください',
    pleaseEnter: '入ってください',
    inviteCodeHeader: 'プロモーション招待コード',
    inviteCode: '招待コード',
    selectCountryOrLang: '顧客の国または優先言語を選択します',
    byCountry: '国別',
    byLanguage: '言語別',
    china: '中国',
    vietnam: 'ベトナム',
  },
  settings: {
    info: '情報',
    reg: '登録日',
    CustomerInfo: '顧客情報',
    name: '名前',
    email: 'メール',
    phone: '電話番号',
    address: '住所',
  },
  unfundedAcc: {
    neverFundedAccs: '資金が入金されていない口座',
    prevFundedZero: '以前に資金が入金された (残高ゼロ)',
    accBalance: '口座残高',
  },
  multiib: {
    level: 'レベル {level}',
    noib: 'このアカウントにはサブ IB がありません。',
  },
  AWCDialog: {
    optIn: '今すぐオプトイン',
    noThanks: 'いいえ、結構です',
  },
  header: {
    bckToCP: 'クライアント ポータルに戻る',
    logout: 'ログアウト',
    chooselang: '言語を選択してください:',
    welcome: 'ようこそ',
  },
  menu: {
    dashboard: 'ダッシュボード',
    ibReport: 'IB レポート',
    rebateReport: 'リベート レポート',
    iblevel: 'マルチレベル IB',
    ibAccs: 'IB アカウント',
    leads: 'リード',
    pendingAccs: '保留中のアカウント',
    unfundedAccs: '未入金アカウント',
    payHistory: '取引履歴',
    ibProfile: 'IB プロフィール',
    refLinks: '紹介リンク',
    contactUs: 'お問い合わせ',
    switchCP: 'クライアントポータルに切り替える',
    allRetailAccs: 'すべての小売アカウント',
    tradeReport: '取引レポート',
  },
  footer: {
    riskWarn: '',
    riskWarn_fsc:
      'リスク警告: デリバティブは複雑な金融商品であり、レバレッジにより急速に資金を失う高いリスクを伴います。デリバティブの仕組みを理解しているかどうか、資金を失う高いリスクを負う余裕があるかどうかを検討する必要があります。',
    riskWarn1: 'リスク警告: CFD はレバレッジ商品であり、リスクを伴います。預託金の全額を失う可能性があります。',
  },
  responseMsg: {
    410: 'パラメータ検証に失敗しました',
    411: 'パスワードが正しくありません',
    420: 'ユーザーが存在しません',
    421: 'パスワードのリセットが拒否されました',
    490: '重複したカード情報を送信しないでください',
    500: 'エラーが発生しました。',
    501: '申請に失敗しました。しばらくしてからもう一度お試しください。',
    505: 'ファイルのアップロードに失敗しました',
    510: 'SMS コードが正しくありません',
    511: '携帯電話に SMS コードを送信できません',
    520: 'ログインに失敗しました。もう一度お試しください。',
    521: 'このデバイスには既にユーザーがログインしています!',
    522: 'もう一度ログインしてください',
    523: 'もう一度ログインしてください',
    524: '残念ながら、あなたのメールアドレスは認識されませんでした',
    525: 'ユーザー名またはパスワードが間違っています',
    526: 'Recaptcha が一致しません',
    527: 'ユーザーは IB ではありません',
    528: 'ユーザーが存在しません',
    529: 'ユーザー名またはパスワードが間違っています',
    530: 'コードが無効です',
    531: 'コードが無効です',
    540: 'あなたの取引口座が見つかりません',
    541: 'リベート アカウントが見つかりません',
    542: '追加のアカウントを開設することはできません',
    544: '身分証明書が確認されるまで、追加アカウントを申請することはできません',
    550: '口座番号が正しくありません',
    551: '残高が不足しています',
    554: 'リクエストされた金額がゼロまたは null です',
    562: 'お支払いを処理できませんでした。もう一度お試しください。問題が解決しない場合は、ライブチャットまたはメール（{email}）でお問い合わせください。',
    564: '国がお支払いチャネルと一致しません',
    565: '銀行コードが正しくありません',
    566: 'お支払い方法が見つかりません',
    567: '残念ながら、このお支払い方法は現在ご利用いただけません。ご不便をおかけして申し訳ございません。',
    581: 'プロモーションへの参加に成功しました。資金を出金するには入金が必要ですのでご注意ください。',
    584: '適用可能なリベートはありません',
    590: '振替口座が見つかりません',
    591: '振替口座が見つかりません',
    593: '口座にクレジットがあるため、選択した口座からの資金振替を処理できません。',
    594: 'この口座からの資金の送金は許可されていません',
    session_timeout: 'セッションがタイムアウトしました。ログイン ページにリダイレクトします。',
  },
  rate: {
    rate: '為替レート ({oldCurrency}/{newCurrency}):',
    result: {
      rateError: '{oldCurrency} から {newCurrency} へのレートの取得に失敗しました。',
      channelError: '支払いチャネルの取得に失敗しました。',
    },
  },
  allRetailAccs: {
    type: {
      all: 'すべて',
      zero: '残高ゼロ',
      funded: '資金あり',
      unfunded: '資金なし',
      deposit: '入金',
      withdrawal: '出金',
    },
    filter: { name: '名前', email: 'メール', account: 'アカウント' },
    placeholder: 'メール/名前/アカウント/モバイルで検索',
    search: '検索',
    tradeHistory: '取引履歴',
    depwith: '入金と出金',
    summary: '概要',
    currency: '通貨',
    totalDeposit: '合計入金額',
    totalWithdrawal: '合計出金額',
    netDeposit: '純入金額',
  },
}
